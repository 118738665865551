import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Dialog from '@/components/ui/Dialog/Dialog'
import FlameIcon from '@/components/ui/Icons/FlameIcon/FlameIcon'
import PollIcon from '@/components/ui/Icons/PollIcon/PollIcon'
import { notificationDanger, notificationSuccess } from '@/components/ui/Notification/Notification'
import Popover from '@/components/ui/Popover/Popover'
import ChatMoreOptionsButton from '@/views/Show/components/ChatBox/components/Chat/ChatMoreOptions/ChatMoreOptionsButton/ChatMoreOptionsButton'
import useLaunchPoll from '@/views/Show/components/ChatBox/components/Chat/ChatMoreOptions/useLaunchPoll'
import CreatePollForm, {
  type PollInputData,
} from '@/views/Show/components/ChatBox/components/CreatePoll/CreatePollForm/CreatePollForm'
import { useStartReactionCounterInShowMutation } from '@/views/Show/operations.generated'

import './ChatMoreOptions.scss'

type ChatMoreOptionsProps = {
  showId: number
  disabled: boolean
  flameCounterDisabled: boolean
  pollDisabled: boolean
  onNewContentAdded: () => void
}

const ChatMoreOptions = (props: ChatMoreOptionsProps) => {
  const { showId, pollDisabled, flameCounterDisabled, disabled, onNewContentAdded } = props
  const { t } = useTranslation()

  const { launchPoll, loading: isPollLaunching } = useLaunchPoll({
    showId,
    onLaunched: () => {
      onNewContentAdded()
    },
  })

  const [launchFlameCounter] = useStartReactionCounterInShowMutation()

  const [isOpen, setIsOpen] = useState(false)
  const [isPollCreationOpen, setIsPollCreationOpen] = useState(false)

  const handleTriggerPollCreation = () => {
    if (pollDisabled) {
      return
    }

    setIsOpen(false)
    setIsPollCreationOpen(true)
  }

  const handleTriggerFlameCounterCreation = useCallback(() => {
    launchFlameCounter({
      variables: {
        input: {
          showId: `Show|${showId}`,
          emoji: '🔥',
        },
      },
      onError: (error) => {
        notificationDanger(error?.message)
      },
      onCompleted: () => {
        notificationSuccess(t('flameCounterSuccess'))
      },
    })
    setIsOpen(false)
  }, [])

  const handleMoreOptionsClick = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  const handleCreatePollComplete = useCallback(async (data?: PollInputData) => {
    if (data) await launchPoll(data)
    setIsPollCreationOpen(false)
  }, [])

  return (
    <>
      <Popover
        className="chat-activity-popover"
        classNameContent="chat-activity-popover-content"
        hideOnMouseOut={false}
        isOpen={isOpen}
        openOnMouseHover={false}
        positions={['top', 'left']}
        content={
          <div className="activities">
            <div
              className={`activity activity-flame-counter  ${flameCounterDisabled ? 'is-disabled' : ''}`}
              onClick={handleTriggerFlameCounterCreation}
            >
              <header className="title">
                <FlameIcon />
                <h2>{t('flameCounterPopoverTitle')}</h2>
              </header>
              <p>{t('flameCounterPopoverContent')}</p>
            </div>

            <div
              className={`activity activity-poll ${pollDisabled ? 'is-disabled' : ''}`}
              onClick={handleTriggerPollCreation}
            >
              <header className="title">
                <PollIcon />
                <h2>{t('pollsPopoverTitle')}</h2>
              </header>
              <p>{t('pollsPopoverContent')}</p>
            </div>
          </div>
        }
        onClickOutside={() => setIsOpen(false)}
      >
        <ChatMoreOptionsButton disabled={disabled} onClick={handleMoreOptionsClick} />
      </Popover>
      {isPollCreationOpen && (
        <Dialog
          className="create-poll-dialog"
          isOpen={true}
          title={t('pollModalTitle')}
          onClose={() => handleCreatePollComplete()}
        >
          <CreatePollForm isLoading={isPollLaunching} onComplete={handleCreatePollComplete} />
        </Dialog>
      )}
    </>
  )
}

export default ChatMoreOptions

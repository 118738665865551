import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { FaXmark } from 'react-icons/fa6'

import Confirm from '@/components/Confirm/Confirm'
import Button from '@/components/ui/Button/Button'
import Card from '@/components/ui/Card/Card'
import { notificationDanger, notificationSuccess } from '@/components/ui/Notification/Notification'
import { useStopReactionCounterInShowMutation } from '@/views/Show/operations.generated'

import './FlameCounter.scss'

interface FlameCounterProps {
  counter: number
  showId: string
}

export const FlameCounter = (props: FlameCounterProps) => {
  const { counter, showId } = props
  const { t } = useTranslation()
  const [stopFlameCounter] = useStopReactionCounterInShowMutation()

  const triggerStopFlameCounter = useCallback(() => {
    stopFlameCounter({
      variables: {
        input: {
          showId,
          emoji: '🔥',
        },
      },
      onError: (error) => {
        notificationDanger(error?.message)
      },
      onCompleted: () => {
        notificationSuccess(t('flameCounterStopSuccess'))
      },
    })
  }, [])

  return (
    <Card className="flame-counter">
      <h2>{t('flameCounterTitle')}</h2>
      <div className="flame-counter-details">
        <span>🔥{counter}</span>
        <Confirm message={t('flameCounterStopConfirm')} onConfirm={triggerStopFlameCounter}>
          <Button icon={<FaXmark />} />
        </Confirm>
      </div>
    </Card>
  )
}

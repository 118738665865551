import Button from '@/components/ui/Button/Button'
import MoreOptionsIcon from '@/components/ui/Icons/MoreOptionsIcon/MoreOptionsIcon'

type ChatMoreOptionsButtonProps = {
  onClick: () => void
  disabled?: boolean
}

const ChatMoreOptionsButton = (props: ChatMoreOptionsButtonProps) => {
  const { onClick, disabled } = props

  return (
    <Button className="chat-more-options-action" disabled={disabled} icon={<MoreOptionsIcon />} onClick={onClick} />
  )
}

export default ChatMoreOptionsButton

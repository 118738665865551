import { useEffect, useRef } from 'react'

import Loader from '@/components/ui/Loader/Loader'
import { ShowPollStatus } from '@/network/graphql/types.generated'
import { trackError } from '@/util/sentry'
import ChatForm from '@/views/Show/components/ChatBox/components/Chat/ChatForm/ChatForm'
import ChatMoreOptions from '@/views/Show/components/ChatBox/components/Chat/ChatMoreOptions/ChatMoreOptions'
import { useCurrentPollQuery, useOnShowPollUpdateSubscription } from '@/views/Show/operations.generated'

import { useChatBoxContext } from '../../ChatBoxProvider'
import { useChat } from '../../hooks/useChat'
import Poll from '../Polls/Poll'

import BanAndUnbanItem from './BanAndUnbanItem'
import { CommentItem } from './CommentItem'
import { FlameCounter } from './FlameCounter/FlameCounter'
import FollowItem from './FollowItem'
import { IncomingRaidItem } from './IncomingRaidItem'
import JoinItem from './JoinItem'
import { ReactionItem } from './ReactionItem'

import './Chat.scss'

export const Chat = () => {
  // TODO: get the following from a Show context instead
  const { showId, isActivityRunning, isShowBroadcasting } = useChatBoxContext()
  const {
    chatItems,
    reactionItems,
    flameCounter,
    emoteMap,
    previousItemsLoaded,
    removeReaction,
    lastFollowItem,
    lastJoinItem,
  } = useChat(showId)

  const showGlobalId = `Show|${showId}`
  const commentListRef = useRef<HTMLDivElement>(null)

  const {
    data: currentPollData,
    updateQuery: updatePollQuery,
    refetch: refetchCurrentPoll,
  } = useCurrentPollQuery({
    skip: !showId,
    variables: { showId: showGlobalId },
    onError: (currentPollError) => {
      trackError(currentPollError, {
        meta: { showId, scope: 'Show.useCurrentPollQuery' },
      })
    },
  })

  useOnShowPollUpdateSubscription({
    skip: !showId,
    variables: { showId: showGlobalId },
    onData: (data) => {
      // As the subscription fields are ShowPollEnd and ShowPollUserParticipation, we need to update the query manually and set the updated values in currentFlashSale
      updatePollQuery((prev) => {
        if (prev?.node.__typename === 'Show' && prev.node.currentShowPoll) {
          if (data.data.data?.showPollUpdate.__typename === 'ShowPollEnd') {
            const newData = {
              ...prev,
              node: {
                ...prev.node,
                currentShowPoll: {
                  ...prev.node.currentShowPoll,
                  status: data.data.data?.showPollUpdate.status,
                },
              },
            }
            return newData
          } else if (data.data.data?.showPollUpdate.__typename === 'ShowPollUserParticipation') {
            const newData = {
              ...prev,
              node: {
                ...prev.node,
                currentShowPoll: {
                  ...prev.node.currentShowPoll,
                  votes: data.data.data?.showPollUpdate.votes,
                },
              },
            }
            return newData
          }
        }
        return prev
      })
    },
  })

  const currentPoll =
    currentPollData?.node.__typename === 'Show' && currentPollData?.node.currentShowPoll
      ? currentPollData.node.currentShowPoll
      : null
  const currentPollStatus = currentPoll?.status

  const handleNewChatContentAdded = async () => {
    if (!currentPoll || !(currentPollStatus === ShowPollStatus.Opened)) {
      await refetchCurrentPoll()
    }
  }

  // Auto scroll to last message
  useEffect(() => {
    if (!commentListRef.current) return

    commentListRef.current.scrollTo({
      top: commentListRef.current.scrollHeight,
      behavior: 'smooth',
    })
  }, [chatItems])

  const isPollRunning = currentPoll?.status === ShowPollStatus.Opened
  const shouldDisableChatAction = !!(!isShowBroadcasting || isActivityRunning)

  const renderCommentItem = (commentItem: any) => {
    switch (commentItem.__typename) {
      case 'ShowRaidIncomingFeedItem':
        return <IncomingRaidItem key={`raid-${commentItem.id}`} item={commentItem} />
      case 'UserCommentFeedItem':
        return <CommentItem key={`comment-${commentItem.id}`} emoteMap={emoteMap} item={commentItem} />

      case 'UserBlockedFromCommentingInShowFeedItem':
      case 'UserUnblockedFromCommentingInShowFeedItem':
        return (
          <BanAndUnbanItem
            key={`ban-${commentItem.id}`}
            timestamp={commentItem.timestamp}
            typename={commentItem.__typename}
            username={commentItem.user.username}
          />
        )
    }
  }

  return (
    <div className="chat">
      <div className="chat-activities">
        {currentPoll && <Poll currentPoll={currentPoll} />}
        {flameCounter !== undefined && <FlameCounter counter={flameCounter} showId={showGlobalId} />}
      </div>

      {!previousItemsLoaded && (
        <div className="flex flex-1 items-center justify-center">
          <Loader />
        </div>
      )}
      {previousItemsLoaded && (
        <div ref={commentListRef} className="chat-content">
          {chatItems.map((comment) => renderCommentItem(comment))}
          {reactionItems.map((reaction) => (
            <ReactionItem
              key={reaction.id}
              reaction={reaction}
              removeReaction={removeReaction} // Call removeReaction when a reaction expires
            />
          ))}
        </div>
      )}
      {lastFollowItem && <FollowItem item={lastFollowItem} />}
      {
        <JoinItem
          avatarWebPUrl={lastJoinItem?.user.avatarWebPUrl}
          timestamp={lastJoinItem?.date}
          username={lastJoinItem?.user.username}
        />
      }

      <div className="chat-form-container">
        <ChatMoreOptions
          disabled={shouldDisableChatAction}
          flameCounterDisabled={flameCounter !== undefined}
          pollDisabled={isPollRunning}
          showId={showId}
          onNewContentAdded={handleNewChatContentAdded}
        />

        <ChatForm showId={showId} />
      </div>
    </div>
  )
}

import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'

import { notificationDanger } from '@/components/ui/Notification/Notification'
import { logger, LOGGER_LEVEL } from '@/network/common/logger'
import { trackError } from '@/util/sentry'
import {
  useAddCommentToShowMutation,
  useGetMostRecentShowFeedItemsLazyQuery,
  useOnNewShowFeedItemSubscription,
} from '@/views/Shows/operations.generated'

import type {
  EmoteSetsFragment,
  ItemFragment,
  JoinFragment,
  ReactionFragment,
  UserBlockedFromCommentingInShowFragment,
  UserFollowFragment,
  UserUnblockedFromCommentingInShowFragment,
} from '@/views/Shows/operations.generated'

const MAX_REACTIONS_IN_CHAT = 50
const MAX_COMMENTS_IN_CHAT = 150

export type BanItemType = UserBlockedFromCommentingInShowFragment & UserUnblockedFromCommentingInShowFragment

type Emote = EmoteSetsFragment['emotes'][number]
export type EmoteMap = { [key: string]: Emote }

export const useChat = (showId: number) => {
  const [addCommentMutation] = useAddCommentToShowMutation()
  const [loadMostRecentFeedItems] = useGetMostRecentShowFeedItemsLazyQuery()
  const showGlobalId = `Show|${showId}`

  const [previousItemsLoaded, setPreviousItemsLoaded] = useState(false)

  const [chatItems, setChatItems] = useState<ItemFragment[]>([])
  const [reactionItems, setReactionItems] = useState<ReactionFragment[]>([])
  const [availableEmoteSets, setAvailableEmoteSets] = useState<EmoteSetsFragment[]>([])
  const [emoteMap, setEmoteMap] = useState<EmoteMap | undefined>()
  const [lastFollowItem, setLastFollowItem] = useState<UserFollowFragment>()
  const [lastJoinItem, setLastJoinItem] = useState<JoinFragment>()
  const [flameCounter, setFlameCounter] = useState<number | undefined>(undefined)

  const handleAddComment = (comment: string) => {
    addCommentMutation({
      variables: { showId: showGlobalId, textContent: comment },
      onError: (err) => {
        notificationDanger(err?.message)
        trackError(err, {
          meta: {
            feature: 'raid.hooks.useRaid',
          },
        })
      },
    })
  }

  // REACTION "QUEUE" System
  // -----------------------
  const addReaction = (newReaction: ReactionFragment) => {
    if (newReaction.reactionCounterStatus === 'STARTING') {
      setFlameCounter(0)
      return
    } else if (newReaction.reactionCounterStatus === 'STOPPING') {
      setFlameCounter(undefined)
      return
    } else if (newReaction.reactionCounterStatus === 'OFF' && flameCounter) {
      setFlameCounter(undefined)
    } else if (newReaction.reactionCounterStatus === 'ON' && newReaction.reactionCounter) {
      setFlameCounter(newReaction.reactionCounter)
    }
    setReactionItems((prevReactions) => {
      const updatedReactions = [...prevReactions, newReaction]
      if (updatedReactions.length > MAX_REACTIONS_IN_CHAT) {
        return updatedReactions.slice(-MAX_REACTIONS_IN_CHAT) // Keeps only the latest reactions
      }
      return updatedReactions
    })
  }

  const removeReaction = useCallback((reactionId: ReactionFragment['id']) => {
    setReactionItems((prevReactions) => prevReactions.filter((reaction) => reaction.id !== reactionId))
  }, [])

  // -----------------------
  const addItemToList = <T extends { id: any }>(
    item: T,
    setList: React.Dispatch<React.SetStateAction<T[]>>,
    sortFunction?: (a: T, b: T) => number,
    threshold?: number
  ) => {
    setList((prevList) => {
      if (prevList.some((existingItem) => existingItem.id === item.id)) {
        return prevList // Return the original list if item exists
      } else {
        const newList = [...prevList, item]
        if (sortFunction) {
          newList.sort(sortFunction) // Sort the list if a sort function is provided
        }
        if (threshold && newList.length > threshold) {
          return newList.slice(-threshold) // Keep only the latest items if a threshold is provided
        }
        return newList
      }
    })
  }

  const removeItemFromChatItemsFromUser = (userId: string) => {
    setChatItems((prevChatItems) => prevChatItems.filter((item) => item?.user?.id !== userId))
  }

  const sortByTimestamp = (a: ItemFragment, b: ItemFragment) => {
    return dayjs(a.date).diff(b.date)
  }

  const parseFeedItem = useCallback((feedItem: any) => {
    if (!feedItem) return
    switch (feedItem.__typename) {
      case 'UserJoinedFeedItem':
        setLastJoinItem(feedItem)
        break
      case 'ReactionFeedItem':
        // Reaction had its own queue system
        addReaction(feedItem)
        break
      case 'UserCommentFeedItem':
        addItemToList(feedItem, setChatItems, sortByTimestamp, MAX_COMMENTS_IN_CHAT)
        break
      case 'UserFollowFeedItem':
        setLastFollowItem(feedItem)
        break
      case 'UserBlockedFromCommentingInShowFeedItem':
      case 'UserUnblockedFromCommentingInShowFeedItem':
        if (feedItem.__typename === 'UserBlockedFromCommentingInShowFeedItem') {
          removeItemFromChatItemsFromUser(feedItem.user.id)
        }
        addItemToList(feedItem, setChatItems, sortByTimestamp, MAX_COMMENTS_IN_CHAT)
        break
      case 'ShowRaidIncomingFeedItem':
        addItemToList(feedItem, setChatItems, sortByTimestamp, MAX_COMMENTS_IN_CHAT)
        break
      default:
        // Handle unknown type
        break
    }
  }, [])

  // Emote map generation, we use a map to avoid looping through all emotes on every render
  useEffect(() => {
    if (!availableEmoteSets) return
    const emoteMap: EmoteMap = {}
    availableEmoteSets.forEach((emoteSet) => {
      emoteSet.emotes.forEach((emote) => {
        // Cast to Emote here is not necessary if emote is already of type Emote
        emoteMap[`:${emote.name}:`] = emote
      })
    })
    setEmoteMap(emoteMap)
  }, [availableEmoteSets])

  // Load most recent feed items
  useEffect(() => {
    setPreviousItemsLoaded(false)
    loadMostRecentFeedItems({
      variables: { showId: showGlobalId },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data?.node?.__typename === 'Show') {
          // Parse feed items
          data.node.feedItemsByTimestampDesc.edges.forEach((edge) => {
            parseFeedItem(edge.node)
          })

          // Parse available emote sets
          const emoteSets = data.node.availableEmoteSets ?? null
          if (emoteSets) {
            setAvailableEmoteSets(emoteSets)
          }
          setPreviousItemsLoaded(true)
        }
      },
      onError: (error) => {
        trackError(error, { meta: { showId, scope: 'useChat.loadMostRecentFeedItems' } })
      },
    })
  }, [loadMostRecentFeedItems, showId])

  // Subscribe to new feed items
  useOnNewShowFeedItemSubscription({
    variables: { showId: showGlobalId },
    onData: ({ data }) => {
      const feedItem = data?.data?.showFeedItemAdded?.feedItem
      if (feedItem) {
        parseFeedItem(feedItem)
      }
      logger({
        level: LOGGER_LEVEL.INFO,
        message: 'New feed item received',
        meta: { showId: showGlobalId, data },
      })
    },
  })

  return {
    handleAddComment,
    chatItems,
    reactionItems,
    flameCounter,
    emoteMap,
    previousItemsLoaded,
    removeReaction,
    lastFollowItem,
    lastJoinItem,
  }
}

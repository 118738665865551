const FlameIcon = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5653 10.9399C13.5653 10.9399 15.1735 8.39345 17.2457 7.99553C16.5706 12.1225 20.4019 15.3204 16.0376 18.7912C13.1485 21.0889 8.27963 20.7399 5.75236 17.9871C3.27369 15.2872 4.50039 11.1391 5.75236 9.00892C6.45181 11.1066 7.99024 11.9074 7.99024 11.9074C8.1532 7.80491 9.9769 7.72168 9.9769 3.69231C14.1396 5.19666 13.9191 9.2484 13.5653 10.9399Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.38462"
      />
      <path
        d="M11.6194 16.0151C12.6675 15.7755 13.1459 14.1544 13.1459 14.1544C13.8962 14.6589 15.6408 17.7883 13.3758 19.5896C11.2337 21.2933 7.7765 19.7902 8.19722 16.9288C8.46596 15.101 9.51045 14.737 10.6367 12.7902C11.7817 14.1544 11.6194 16.0151 11.6194 16.0151Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.38462"
      />
    </svg>
  )
}

export default FlameIcon
